<template>
  <div>
    <template v-if="state.ready">
        <template  v-if="meeting">
            <v-container>
                <v-row>
                    <v-col>
                        <EventCard :flat="true" @launchPreMeetingSurvey="launchPreMeetingSurvey" @launchPostMeetingSurvey="launchPostMeetingSurvey" :meeting="meeting"/>
                    </v-col>
                </v-row>
            </v-container>

            <div style="background-color: whitesmoke; text-align: left;">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-tabs v-model="activeTab" background-color="transparent">
                                <v-tab v-for="(tab, index) in tabs" :key="`${tab.key}_${index}`" :class="[{'active':index==activeTab}]" @click="selectTab(tab)" :disabled="tab.disabled ? tab.disabled : false">
                                    {{tab.label}}
                                </v-tab>
                            </v-tabs>


                            <v-tabs-items v-model="activeTab" style="background-color: transparent;">
                                <v-tab-item v-for="(tab, index) in tabs" :key="index">
                                    <component :is="tab.component" :definition="meeting[tab.resource_key]" :eventId="event_id" style="margin: 25px;" :activeTab="activeTab" :context_key="tab.context_key"/>
                                </v-tab-item>
                            </v-tabs-items>

                        </v-col>
                    </v-row>
                </v-container>        
            </div>
        </template>
        <template v-else>
            <v-container>
                <v-row>
                    <v-col>
                        Unable to locate meeting
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template>

    <center v-else>
        Loading
    </center>
  </div>
</template>

<script>
import EventCard from '@/components/Event/EventCard'
import Agenda from '@/components/Event/lib/agenda'
import MeetingSystem from '@/components/Event/eventSystem.js'
import Resources from '@/components/Resources/Resources'
export default {
    name: "EventDetails",
    components: {
        EventCard
    },
    created: async function(){
        this.meetingsAPI = new MeetingSystem(this)
        if(this.meetings==undefined){
            let meetings = await this.meetingsAPI.getMeetings()
            this.$store.commit('meetings',meetings)
        }
        this.setTab()
        this.state.ready = true
    },
    data: function(){
        return {
            state: {
                ready: false
            },
            meetingsAPI: null,
            activeTab: 'agenda',
            tabs: [
                {
                    key: 'agenda',
                    resource_key: 'agenda',
                    label: 'agenda',
                    component: Agenda,
                    context_key: null
                },
                {
                    key: 'resources',
                    resource_key: 'resources',
                    label: 'Pre-Meeting Materials',
                    component: Resources,
                    context_key: null,
                },
                {
                    key: 'post_meeting_materials',
                    resource_key: 'resources',                    
                    label: 'Post-Meeting Materials',
                    component: Resources,
                    disabled: false,
                    context_key: 'post_meeting_materials'
                }                
            ],
            videoTypes: ['mp4']
        }
    },
    methods: {
        setTab: function(){
            if(this.urlTabSelection=='resources'){
                this.activeTab = 1
            }else if(this.urlTabSelection=='post_meeting_materials'){
                this.activeTab = 2
            }else{
                this.activeTab = 0
            }
        },
        selectTab: function(tab){
            let meeting = this.meeting
            this.$router.push({name:'EventDetailsTab',params:{event_id: meeting.id, tab:tab.key}})
        },
        launchPreMeetingSurvey: function(meeting){
            this.$router.push({name:'PreMeetingSurvey',params:{event_id:meeting.id}})
        },
        launchPostMeetingSurvey: function(meeting){
            this.$router.push({name:'PostMeetingSurvey',params:{event_id:meeting.id}})
        }
    },
    computed: {
        event_id: function(){
            return parseInt(this.$route.params.event_id,10)
        },
        meetings: function(){
            return this.$store.getters.meetings
        },
        meetingMap: function(){
            let meetings = this.$store.getters.meetings ? this.$store.getters.meetings : []
            let output = {}
            for(let i=0; i<meetings.length; i++){
                let meeting = meetings[i]
                output[meeting.id] = meeting
            }
            return output
        },
        meeting: function(){
            let meetingMap = this.meetingMap
            let event_id = this.event_id
            return meetingMap[event_id]
        },
        urlTabSelection: function(){
            let tab = this.$route.params.tab
            return tab
        }
    },
    watch: {
        urlTabSelection: function(){
            this.setTab()
        },
        state: {
            deep: true,
            handler: function(){
                let ready = this.state.ready
                let meeting = this.meeting
                if(ready && !meeting){
                    console.log('invalid event url')
                    let self = this
                    setTimeout(function(){
                        self.$router.push({name:'Home'})
                    },2000)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.active{
    font-weight: bold;
    color: $red ;
}

</style>

<style lang="css" scoped>
::v-deep .v-tabs-slider {
    background-color: #BF1E2B;
}
</style>