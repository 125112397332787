<template>
  <div>
    <div v-html="definition[language]"></div>
  </div>
</template>

<script>
export default {
    props: {
        definition: {
            type: Object,
            required: true
        }
    },
    computed: {
      language: function(){
        return this.$store.getters.language
      }
    }
}
</script>

<style>

</style>